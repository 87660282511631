body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Lilita One', cursive;
    font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* --------------------------------------------------- */

/* FONT SIZES */
.txt-1 {
  font-size: 0.5rem;
}
.txt-2 {
  font-size: 0.7rem;
}
.txt-3 {
  font-size: 0.9rem;
}
.txt-4 {
  font-size: 1.1rem;
}
.txt-5 {
  font-size: 1.3rem;
}
.txt-6 {
  font-size: 1.5rem;
}
.txt-7 {
  font-size: 1.7rem;
}
.txt-8 {
  font-size: 1.9rem;
}
.txt-9 {
  font-size: 2.1rem;
}
.txt-10 {
  font-size: 2.3rem;
}

/* --------------------------------------------------- */

/* FONT WEIGHT */
.font-weight-100 {
  font-weight: 100;
}
.font-weight-200 {
  font-weight: 200;
}
.font-weight-300 {
  font-weight: 300;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-700 {
  font-weight: 700;
}
.font-weight-800 {
  font-weight: 800;
}

/* --------------------------------------------------- */

/* WIDTHS */
.w-5 {
  width: 5%;
}
.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 25%;
}
.w-30 {
  width: 30%;
}
.w-35 {
  width: 35%;
}
.w-40 {
  width: 40%;
}
.w-45 {
  width: 45%;
}
.w-50 {
  width: 50%;
}
.w-55 {
  width: 55%;
}
.w-60 {
  width: 60%;
}
.w-65 {
  width: 65%;
}
.w-70 {
  width: 70%;
}
.w-75 {
  width: 75%;
}
.w-80 {
  width: 80%;
}
.w-85 {
  width: 85%;
}
.w-90 {
  width: 90%;
}
.w-95 {
  width: 95%;
}
.w-100 {
  width: 100%;
}

 @media (min-width: 500px) {
  .w-sm-75 {
    width: 75% !important;
  }
}
/* @media (max-width: 767px) {
  .h-md-100 {

  }
} */
/* @media (max-width: 1000px) {
  .w-md-85 {
    width: 85%;
  }
} */
@media (min-width: 1201px) {
  .w-lg-70 {
    width: 70%;
  }
}


/* --------------------------------------------------- */

/* BORDERS */
.border-top-white {
  border-top: 2px solid white
}
.border-right-white {
  border-right: 2px solid white
}
.border-bottom-white {
  border-bottom: 2px solid white
}
.border-left-white {
  border-left: 2px solid white
}

/* --------------------------------------------------- */

/* SOCIAL LINK */

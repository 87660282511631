.download-btn {
	font-weight: 600;
	border-width: 1.5px !important;
	font-weight: 600 !important;
}
.download-btn:hover {
	color: black !important;
	background-color: white !important;
	border-color: blue;
	border-width: 1.5px !important;
}

.react-pdf__Page__annotations {
	display: none !important
}

.react-pdf__Page__canvas {
	width: 65vw !important;
	height: auto !important;
	box-shadow: 0px 12px 28px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(255, 255, 255, 0.05) inset;
}

@media (max-width: 767px) {
	.react-pdf__Page__canvas {
		width: 95vw !important;
		height: auto !important;
	}
}

@media (min-width: 768px) and (max-width: 992px) {
	.download-btn {
		width: 130px !important;
	}
}
.left-column-container {
	background-color: rgba(230, 218, 206, 1);

}


#section1Container {
	letter-spacing: 0.12rem;
}

.hello-text {
	font-size: 5.5rem;
}

.resume-btn {
	font-weight: 600;
	border-width: 1.5px !important;
	font-weight: 600 !important;
}
.resume-btn:hover {
	color: black !important;
	background-color: white !important;
	border-color: blue;
	border-width: 1.5px !important;
}

.projects-btn {
	color: black;
	border-color: black;
	font-weight: 600;
	border-width: 1.5px !important;
	font-weight: 600 !important;
}


@media (min-width: 1001px) {
	.w-lg-70 {
	  width: 70%;
	}
}
@media (min-width: 1300px) {
	.w-xl-60 {
	  width: 60%;
	}
}

.profile-card {
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.profile-card-social-links {
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.profile-pic {
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@media (min-width: 500px) {
	.w-sm-75 {
		width: 75% !important;
	}
}
@media ((min-width: 769px) and (max-width: 1100px)) {
	.w-lg-90 {
		width: 90% !important;
	}
}
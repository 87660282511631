.nameNav, .jobTitleNav {
	letter-spacing: 0.18vw;
}

.menuNav {
	overflow-y: scroll;
	list-style: none;
	position: absolute;
	top: 16vh;
	background: #282c34;
	right: 0;
	bottom: 0;
	height: 33vh;
	width: 0;
	overflow: hidden;
	max-width: 250px;
	z-index: 9;
	padding-inline-start: 0px;
	border-bottom-left-radius: 13% 20%;
  }

  .menuNav.showMenu {
	width: 75%;
	transition: all 200ms ease-in;
  }

  .menuNav:not(.showMenu) {
	transition: all 200ms ease-out;
  }

  .menuNav li {
	margin-top: 1rem;
	position: relative;
	left: 0%;
  }

  .active {
	border-bottom: 2px solid #0082ca
  }
  .nav-link {
	display: inline-block;
	position: relative;
  }

  .nav-link::after {
	content: '';
	position: absolute;
	width: 100%;
	transform: scaleX(0);
	height: 2px;
	bottom: 0;
	left: 0;
	background-color: #0087ca;
	transform-origin: bottom right;
	transition: transform 0.25s ease-out;
  }

  .nav-link:not(.active):hover::after {
	transform: scaleX(1);
	transform-origin: bottom left;
  }
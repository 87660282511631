.App {
  /* text-align: center; */

}

body {
  font-weight: 500;
}

.App-header {
  background-color: #282c34;
  min-height: 16vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  background-color: #282c34;
  min-height: 16vh;
  display: flex;
  flex-direction: column;
  color: white;
}